.rainbow-text {
    font-weight: bold;
    background-image: linear-gradient(45deg, red, orange, yellow, green, cyan, blue, violet);
    background-size: 300% 100%; /* 增大背景图的宽度 */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    display: inline-block;
    animation: rainbow 6s linear infinite; /* 增加动画时长使流动更平滑 */
  
    @keyframes rainbow {
        0% {
          background-position: -100% 0%; /* 初始时背景位于左侧外部 */
        }
        100% {
          background-position: 100% 0%; /* 最终背景位于右侧外部 */
        }
      }
  }
  
.views_loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #555;
  border-radius: 50%;
  display: inline-block;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
